import React, { Suspense } from 'react';
import './App.scss';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { Routes, Route } from 'react-router-dom';
import Spinner from './components/shared/Spinner';
import AddBlogPost from './components/blog/AddBlogPost';
import useAnalytics from './hooks/useAnalytics';
import AnalyticsWrapper from './components/AnalyticsWrapper';

const Blog = React.lazy(() => import('./pages/Blog'));
const BlogArchive = React.lazy(() => import('./pages/BlogArchive'));
const Home = React.lazy(() => import('./pages/Home'));
const Contact = React.lazy(() => import('./pages/Contact'));
const About = React.lazy(() => import('./pages/About'));
const Tools = React.lazy(() => import('./pages/Tools'));
const Tool = React.lazy(() => import('./pages/Tool'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

const App = () => {
  const analyticsIsActive = useAnalytics();

  return (
    <div className="open-garden">
      <Header />
      <Suspense fallback={<Spinner />}>
        <AnalyticsWrapper initialized={analyticsIsActive}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/blog/:slug" element={<Blog />} />
            <Route path="/blog" element={<BlogArchive BlogPosts={[]} />} />
            <Route path="/blog/archive" element={<BlogArchive BlogPosts={[]} />} />
            {/* <Route path="/blog" >
          <Route index element={<Blog />} />
        </Route> */}
            <Route path="/blog/add" element={<AddBlogPost />} />
            <Route path="/about" element={<About />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/tool/:toolType" element={<Tool />} />
            <Route path="/contact" element={<Contact />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </AnalyticsWrapper>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;