import { FC, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "../logo/Logo";


type RightSideProps = {
}

export const RightSide: FC<RightSideProps> = props => {
    const componentIsMounted = useRef(true)
    useEffect(() => {
        componentIsMounted.current = true

        return () => {
            componentIsMounted.current = false
        }
    }, [])

    return (
        <div className="footer">
            <section className="footer__contact">
                <h3>Kontakt</h3>
                <ul>
                    <li>
                        <Link to="contact" preventScrollReset={true}>Kontakta oss</Link>
                    </li>
                </ul>
            </section>
            <section className="footer__tribute">
                <Logo type="color" height={100} width={100} />
                <p>© 2023</p>
            </section>
            <section className="footer__sitemap">
                <h3>Sitemap</h3>
                <ul>
                    <li>
                        <Link to="/home" preventScrollReset={false}>Hem</Link>
                    </li>
                    <li>
                        <Link to="/blog/archive" preventScrollReset={false}>Blog arkiv</Link>
                    </li>
                    <li>
                        <Link to="/about" preventScrollReset={false}>Om oss</Link>
                    </li>
                    <li>
                        <Link to="/tools" preventScrollReset={false}>Verktyg</Link>
                    </li>
                </ul>
            </section>
        </div>
    )
}

RightSide.propTypes = {

}

RightSide.defaultProps = {

};


export default RightSide
