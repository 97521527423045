import { IBlogPost } from "../interfaces/IBlogPost";
// let blogPostsJsonFile = require('../assets/BlogPosts.json')
import blogPosts from '../assets/BlogPosts.json';


export enum mimeTypes {
    json = 'application/json',
    csv = 'text/csv',
    xml = 'text/xml',
    txt = 'text/plain'
}

class JsonHelper {
    private static fileName: string = "BlogPosts";

    static readJsonFile(): Promise<IBlogPost[]> {
        return new Promise((resolve, reject) => {
            try {
                const result: IBlogPost[] = blogPosts as unknown as IBlogPost[];
                resolve(result);
            } catch (e) {
                reject(e);
            }
        });
    }
    static writeJsonFile(blogPost: IBlogPost, mimeType: string): void {
        let data = JSON.stringify(blogPost);
        const blob = new Blob([data], { type: `${mimeType};charset=utf-8;` });
        const link = document.createElement('a');

        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${this.fileName}.${mimeType}`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export { JsonHelper };


// https://codesandbox.io/s/4t2xb?file=/src/App.js
// https://gist.github.com/darrenmothersele/e015ef13b96d26d778161cbc56b6a5f5







//  private static file: string = "../src/assets/BlogPosts.json";

//     //read json file
//     public static readJsonFile(): any {
//         let json = readFileSync(this.file, 'utf8');
//         return JsonHelper.parse(json);
//     }
//     //write json file
//     public static writeJsonFile(obj: any): void {
//         let json = JsonHelper.stringify(obj);
//         writeFileSync(this.file, json, 'utf8');
//     }

//     private static parse(json: string): any {
//         let result: any = null;
//         try {
//             result = JSON.parse(json);
//         } catch (e) {
//             console.log(e);
//         }
//         return result;
//     }
//     private static stringify(obj: any): string {
//         let result: string = "";
//         try {
//             result = JSON.stringify(obj);
//         } catch (e) {
//             console.log(e);
//         }
//         return result;
//     }