import React from "react";
import { FC, useEffect, useState } from "react";
import { ArticleType, BlogType, IArticleBullet, IBlogArticle, IBlogPost, ILink, ToolType } from "../../interfaces/IBlogPost";
import { IAffiliate } from "../../interfaces/IAffiliate";
import BlogPost from "./BlogPost";
import { Guid } from "guid-typescript";
import { IGeolocation } from "../../interfaces/IGeolocation";
import { GeoHelper } from "../../helpers/GeoHelper";
import { JsonHelper, mimeTypes } from "../../helpers/JsonHelper";
import Prism from "prismjs"
import "prismjs/themes/prism-tomorrow.css";

type AddBlogPostProps = {
}

const AddBlogPost: FC<AddBlogPostProps> = props => {
    const [blogPost, setBlogPost] = useState<IBlogPost>();
    const [blogArticle, setBlogArticle] = useState<IBlogArticle | undefined>();
    const [link, setLink] = useState<ILink | undefined>();
    const [listBullet, setListBullet] = useState<IArticleBullet | undefined>();
    const [affiliate, setAffiliate] = useState<IAffiliate | undefined>();

    const blogTypes = Object.values(BlogType).filter((v) => isNaN(Number(v))).map((value, index) => {
        return (
            <option value={value} key={index}>{value}</option>
        )
    });
    const articleTypes = Object.values(ArticleType).filter((v) => isNaN(Number(v))).map((value, index) => {
        return (
            <option value={value} key={index}>{value}</option>
        )
    });
    const toolTypes = Object.values(ToolType).filter((v) => isNaN(Number(v))).map((value, index) => {
        return (
            <option value={value} key={index}>{value}</option>
        )
    });
    const setArticleType = (e: any) => {
        console.log(e.target.value)
        setBlogArticle({
            ...blogArticle!,
            Type: e.target.value
        });
        console.log(blogArticle)
        setListBulletContent("");
    }

    const setListBulletStrong = (e: string) => {
        console.log(e)
        let numberOfBullets = blogArticle?.Bullets?.length || 0;
        if (listBullet === undefined) {
            setListBullet({
                ...listBullet!,
                Id: Guid.create().toString(),
                PartNumber: 1,
                Strong: e,
            });
        }
        else {
            setListBullet({
                ...listBullet!,
                Id: Guid.create().toString(),
                Strong: e,
                PartNumber: numberOfBullets + 1,
            });
        }

        console.log(listBullet)
    }
    const setListBulletContent = (e: string) => {
        console.log(e)
        let numberOfBullets = blogArticle?.Bullets?.length || 0;
        if (listBullet === undefined) {
            setListBullet({
                ...listBullet!,
                Id: Guid.create().toString(),
                PartNumber: 1,
                Content: e,
            });
        }
        else {
            setListBullet({
                ...listBullet!,
                Id: Guid.create().toString(),
                Content: e,
                PartNumber: numberOfBullets + 1,
            });
        }

        console.log(listBullet)
    }

    const addbulletToArticle = () => {
        if (listBullet === undefined) {
            return;
        }
        if (blogArticle?.Bullets === undefined) {
            setBlogArticle({
                ...blogArticle!,
                Bullets: [listBullet!]
            });

        }
        else {
            setBlogArticle({
                ...blogArticle!,
                Bullets: [...blogArticle?.Bullets!, listBullet!]
            });
        }

        setListBullet(undefined);
    }

    const addAffiliateToArticle = async () => {
        if (affiliate === undefined) {
            alert("Affiliate is undefined")
            return;
        }
        if (blogArticle?.Affiliate1 === undefined) {
            setBlogArticle({
                ...blogArticle!,
                Affiliate1: affiliate
            });
            setAffiliate(undefined);
            return;
        }
        if (blogArticle?.Affiliate2 === undefined) {
            setBlogArticle({
                ...blogArticle!,
                Affiliate2: affiliate
            });
            setAffiliate(undefined);
            return;
        }
        if (blogArticle?.Affiliate3 === undefined) {
            setBlogArticle({
                ...blogArticle!,
                Affiliate3: affiliate
            });
            setAffiliate(undefined);
            return;
        }

        setAffiliate(undefined);
    }
    const addLinkToArticle = async () => {
        if (link === undefined) {
            alert("Link is undefined")
            return;
        }

        if (blogArticle?.Links === undefined) {
            setBlogArticle({
                ...blogArticle!,
                Links: [
                    {
                        ...link!, Id: Guid.create().toString()
                    }
                ]
            });
            setLink(undefined);
            return;
        }
        setBlogArticle({
            ...blogArticle!,
            Links: [
                ...blogArticle?.Links!,
                {
                    ...link!, Id: Guid.create().toString()
                }
            ]
        });
        setLink(undefined);
    }

    const addArticleToBlogPost = async () => {
        let numberOfArticles = blogPost?.Content?.length || 0;
        if (blogArticle === undefined) {
            alert("Article is undefined")
            return;
        }

        if (blogPost === undefined || blogPost.Content === undefined) {
            setBlogPost({
                ...blogPost!,
                Content: [
                    {
                        ...blogArticle, Id: Guid.create().toString(), PartNumber: numberOfArticles + 1
                    }
                ]
            });

            setBlogArticle(undefined);
            return;
        }

        setBlogPost({
            ...blogPost!,
            Content: [
                ...blogPost.Content,
                { ...blogArticle, Id: Guid.create().toString(), PartNumber: numberOfArticles + 1 }
            ]
        });

        setBlogArticle(undefined);

        console.log(blogPost);
    }

    async function GetGeolocation() {
        const response = await fetch(GeoHelper.geo_url)
        const data = await response.json();
        return data as IGeolocation;
    }

    const saveBlogPost = async () => {

        if (blogPost === undefined) {
            alert("blogPost is undefined")
            return;
        }

        setBlogPost({
            ...blogPost!,
            Id: Guid.create().toString(),
            PublishDate: new Date()
        });
    }

    const submitBlogPost = async () => {
        if (blogPost === undefined) {
            alert("blogPost is undefined")
            return;
        }

        console.log(blogPost);
        let body = JSON.stringify(blogPost)
        console.log(body);

        JsonHelper.writeJsonFile(blogPost!, mimeTypes.json);
    }

    useEffect(() => {
        console.log(listBullet)
        return () => {
        }
    }, [listBullet])
    useEffect(() => {
        console.log(blogArticle)
        return () => {
        }
    }, [blogArticle])
    useEffect(() => {
        console.log("blogPost", blogPost);

        return () => {
        }
    }, [blogPost])

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return (
        <React.Fragment>

            <BlogPost Post={blogPost} />

            <div className="blog-add-container">
                <section className="blog-add__form">
                    <div>
                        <h1>Skapa ny bloggpost</h1>
                        <p>Skapa en ny bloggpost genom att fylla i formuläret nedan.</p>
                    </div>
                    <form className="form__group">
                        <fieldset className="addBlog__intro">
                            <legend>Intro</legend>
                            <label className="form__label">
                                Header (h1):
                                <input type="text" name="header" className="form__input"
                                    required
                                    value={blogPost?.Title || ""}
                                    onChange={(e: any) => {
                                        setBlogPost({
                                            ...blogPost!,
                                            Title: e.target.value
                                        });
                                    }}
                                />
                            </label>
                            <label className="form__label">
                                Description:
                                <textarea name="Description" className="form__input"
                                    required
                                    value={blogPost?.Description || ""}
                                    onChange={(e: any) => {
                                        setBlogPost({
                                            ...blogPost!,
                                            Description: e.target.value
                                        });
                                    }}
                                />
                            </label>
                            <label className="form__label">
                                Image:
                                {/* <input type="file" name="Image" /> */}
                                <input type="text" name="header_image" className="form__input"
                                    required
                                    value={blogPost?.ImageUrl || ""}
                                    onChange={(e: any) => {
                                        setBlogPost({
                                            ...blogPost!,
                                            ImageUrl: e.target.value
                                        });
                                    }}
                                />
                            </label>
                            <label className="form__label">
                                Slug:
                                <input type="text" name="slug" className="form__input"
                                    required
                                    value={blogPost?.Slug || ""}
                                    onChange={(e: any) => {
                                        setBlogPost({
                                            ...blogPost!,
                                            Slug: e.target.value
                                        });
                                    }}
                                />
                            </label>
                            <label className="form__label">
                                Type:
                                <select name="type" className="form__input"
                                    required
                                    value={blogPost?.Type || ""}
                                    onChange={(e: any) => {
                                        setBlogPost({
                                            ...blogPost!,
                                            Type: e.target.value
                                        });
                                    }}>
                                    <option value="">Välj typ</option>
                                    {blogTypes}
                                </select>
                            </label>
                            {/* <input type="submit" value="Submit" /> */}
                        </fieldset>

                        {/* Article section */}
                        <label className="form__label">
                            Article type:
                            <select name="type" className="form__input"
                                required
                                value={blogArticle?.Type || ""}
                                onChange={(e: any) => {
                                    setArticleType(e);
                                }}>
                                <option value="">Välj typ</option>
                                {articleTypes}
                            </select>
                        </label>

                        {/* if type is list */}
                        {(blogArticle?.Type.toString() === "List") &&
                            <fieldset className="addBlog__content">
                                <legend>Content:</legend>
                                <label className="form__label">
                                    Header / Question (h2):
                                    <input type="text" name="header_question" className="form__input"
                                        required
                                        value={blogArticle?.Header || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                Header: e.target.value
                                            });
                                        }}
                                    />
                                </label>
                                <label className="form__label">
                                    Header / Answer (h3):
                                    <input type="text" name="header_answer" className="form__input"
                                        value={blogArticle?.Answer || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                Answer: e.target.value
                                            });
                                        }}
                                    />
                                </label>
                                <label className="form__label">
                                    Detailed answer:
                                    <textarea name="answer" className="form__input"
                                        value={blogArticle?.Body || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                Body: e.target.value
                                            });
                                        }}
                                    />
                                </label>
                                <label className="form__label">
                                    Add Tool:
                                    <select name="type" className="form__input"
                                        required
                                        value={blogArticle.Tool || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                Tool: e.target.value
                                            });
                                        }}>
                                        <option value="">Välj typ</option>
                                        {toolTypes}
                                    </select>
                                </label>
                                <fieldset className="addBlog__content-list">
                                    <legend>List:</legend>
                                    <label className="form__label">
                                        Bullet strong: (rubrik)
                                        <input type="text" name="link_name" className="form__input"
                                            value={listBullet?.Strong || ""}
                                            onChange={(e: any) => {
                                                setListBulletStrong(e.target.value);
                                            }}
                                        />
                                    </label>
                                    <label className="form__label">
                                        Bullet content:
                                        <input type="text" name="link_name" className="form__input"
                                            value={listBullet?.Content || ""}
                                            onChange={(e: any) => {
                                                setListBulletContent(e.target.value);
                                            }}
                                        />
                                    </label>
                                    <fieldset className="addBlog__content-list">
                                        <legend>Add link to bullet:</legend>
                                        <label className="form__label">
                                            Link name:
                                            <input type="text" name="link_name" className="form__input"
                                                value={listBullet?.Link?.Name || ""}
                                                onChange={(e: any) => {
                                                    setListBullet({
                                                        ...listBullet!,
                                                        Link: { ...listBullet!.Link, Name: e.target.value }
                                                    });
                                                }}
                                            />
                                        </label>
                                        <label className="form__label">
                                            Link url:
                                            <input type="text" name="link_name" className="form__input"
                                                value={listBullet?.Link?.Url || ""}
                                                onChange={(e: any) => {
                                                    setListBullet({
                                                        ...listBullet!,
                                                        Link: { ...listBullet!.Link, Url: e.target.value }
                                                    });
                                                }}
                                            />
                                        </label>
                                    </fieldset>
                                    <fieldset className="addBlog__content-affiliate">
                                        <legend>Add affiliate to bullet:</legend>
                                        <label className="form__label">
                                            Affiliate name:
                                            <input type="text" name="affiliate_name" className="form__input"
                                                required
                                                value={listBullet?.Affiliate?.Name || ""}
                                                onChange={(e: any) => {
                                                    setListBullet({
                                                        ...listBullet!,
                                                        Affiliate: { ...listBullet!.Affiliate, Name: e.target.value }
                                                    });
                                                }}
                                            />
                                        </label>
                                        <label className="form__label">
                                            Affiliate description:
                                            <input type="text" name="affiliate_description" className="form__input"
                                                value={listBullet?.Affiliate?.Description || ""}
                                                onChange={(e: any) => {
                                                    setListBullet({
                                                        ...listBullet!,
                                                        Affiliate: { ...listBullet!.Affiliate, Description: e.target.value }
                                                    });
                                                }}
                                            />
                                        </label>
                                        <label className="form__label">
                                            Affiliate url:
                                            <input type="text" name="affiliate_url" className="form__input"
                                                value={listBullet?.Affiliate?.Url || ""}
                                                onChange={(e: any) => {
                                                    setListBullet({
                                                        ...listBullet!,
                                                        Affiliate: { ...listBullet!.Affiliate, Url: e.target.value }
                                                    });
                                                }}
                                            />
                                        </label>
                                        <label className="form__label">
                                            Affiliate image url:
                                            <input type="text" name="affiliate_image" className="form__input"
                                                value={affiliate?.ImageUrl || ""}
                                                onChange={(e: any) => {
                                                    setAffiliate({
                                                        ...affiliate!,
                                                        ImageUrl: e.target.value
                                                    });
                                                }}
                                            />
                                        </label>
                                    </fieldset>
                                    <input type="button" className="form__button" value="Add bullet" onClick={addbulletToArticle} />
                                </fieldset>
                                <label className="form__label">
                                    Final words (h3):
                                    <input type="text" name="header_final-words" className="form__input"
                                        value={blogArticle?.FinalWords || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                FinalWords: e.target.value
                                            });
                                        }}
                                    />
                                </label>
                                <input type="button" className="form__button" value="Add article" onClick={addArticleToBlogPost} />
                            </fieldset>
                        }
                        {/* if type is text */}
                        {(blogArticle?.Type.toString() === "Text") &&
                            <fieldset className="addBlog__content">
                                <legend>Content:</legend>
                                <label className="form__label">
                                    Header / Question (h2):
                                    <input type="text" name="header_question" className="form__input"
                                        required
                                        value={blogArticle?.Header || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                Header: e.target.value
                                            });
                                        }}
                                    />
                                </label>
                                <label className="form__label">
                                    Header / Answer (h3):
                                    <input type="text" name="header_answer" className="form__input"
                                        value={blogArticle?.Answer || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                Answer: e.target.value
                                            });
                                        }}
                                    />
                                </label>
                                <label className="form__label">
                                    Detailed answer:
                                    <textarea name="answer" className="form__input"
                                        value={blogArticle?.Body || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                Body: e.target.value
                                            });
                                        }}
                                    />
                                </label>
                                <label className="form__label">
                                    Add Tool:
                                    <select name="type" className="form__input"
                                        required
                                        value={blogArticle.Tool || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                Tool: e.target.value
                                            });
                                        }}>
                                        <option value="">Välj typ</option>
                                        {toolTypes}
                                    </select>
                                </label>
                                <fieldset className="addBlog__content-affiliate">
                                    <legend>Affiliate:</legend>
                                    <label className="form__label">
                                        Affiliate name:
                                        <input type="text" name="affiliate_name" className="form__input"
                                            required
                                            value={affiliate?.Name || ""}
                                            onChange={(e: any) => {
                                                setAffiliate({
                                                    ...affiliate!,
                                                    Name: e.target.value
                                                });
                                            }}
                                        />
                                    </label>
                                    <label className="form__label">
                                        Affiliate description:
                                        <input type="text" name="affiliate_description" className="form__input"
                                            value={affiliate?.Description || ""}
                                            onChange={(e: any) => {
                                                setAffiliate({
                                                    ...affiliate!,
                                                    Description: e.target.value
                                                });
                                            }}
                                        />
                                    </label>
                                    <label className="form__label">
                                        Affiliate url:
                                        <input type="text" name="affiliate_url" className="form__input"
                                            value={affiliate?.Url || ""}
                                            onChange={(e: any) => {
                                                setAffiliate({
                                                    ...affiliate!,
                                                    Url: e.target.value
                                                });
                                            }}
                                        />
                                    </label>
                                    <label className="form__label">
                                        Affiliate image url:
                                        <input type="text" name="affiliate_image" className="form__input"
                                            value={affiliate?.ImageUrl || ""}
                                            onChange={(e: any) => {
                                                setAffiliate({
                                                    ...affiliate!,
                                                    ImageUrl: e.target.value
                                                });
                                            }}
                                        />
                                    </label>
                                    <input type="button" className="form__button" value="Add affiliate" onClick={addAffiliateToArticle} />
                                </fieldset>

                                <fieldset className="addBlog__content-link">
                                    <legend>Link:</legend>
                                    <label className="form__label">
                                        Link name:
                                        <input type="text" name="link_name" className="form__input"
                                            value={link?.Name || ""}
                                            onChange={(e: any) => {
                                                setLink({
                                                    ...link!,
                                                    Name: e.target.value
                                                });
                                            }}
                                        />
                                    </label>
                                    <label className="form__label">
                                        Link url:
                                        <input type="text" name="link_name" className="form__input"
                                            value={link?.Url || ""}
                                            onChange={(e: any) => {
                                                setLink({
                                                    ...link!,
                                                    Url: e.target.value
                                                });
                                            }}
                                        />
                                    </label>
                                    <input type="button" className="form__button" value="Add link" onClick={addLinkToArticle} />
                                </fieldset>

                                <label className="form__label">
                                    Final words (h3):
                                    <input type="text" name="header_final-words" className="form__input"
                                        value={blogArticle?.FinalWords || ""}
                                        onChange={(e: any) => {
                                            setBlogArticle({
                                                ...blogArticle!,
                                                FinalWords: e.target.value
                                            });
                                        }}
                                    />
                                </label>
                                <input type="button" className="form__button" value="Add article" onClick={addArticleToBlogPost} />
                            </fieldset>
                        }
                        <input type="button" className="form__button" value="Save blog post" onClick={saveBlogPost} />
                        <input type="button" className="form__button" value="Submit blog post" onClick={submitBlogPost} />
                    </form>
                </section>

                <section className="blog-add__raw">
                    <pre>
                        <code>
                            <code className={`language-${"json"}`}>{JSON.stringify(blogPost)}</code>
                        </code>
                    </pre>
                </section>

            </div>

        </React.Fragment>
    )
}

AddBlogPost.propTypes = {};
AddBlogPost.defaultProps = {};

export default AddBlogPost