import React from "react";
import { FC, useEffect, useRef } from "react";
import Logo from "../logo/Logo";
import HeaderNav from "./Nav";


type HeaderProps = {

}

export const Header: FC<HeaderProps> = props => {
    const componentIsMounted = useRef(true)

    useEffect(() => {
        componentIsMounted.current = true

        return () => {
            componentIsMounted.current = false
        }
    }, [])

    return (
        <React.Fragment>
            {
                <div className="header">
                    <div className="header__logo">
                        <Logo type="color" height={150} width={150} />
                    </div>
                    <div className="header__nav">
                        <HeaderNav />
                    </div>
                    {/* <div className="header__options"></div> */}
                </div>
            }
        </React.Fragment>
    )
}

Header.propTypes = {

}

Header.defaultProps = {

};


export default Header