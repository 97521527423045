import { IAffiliate } from "./IAffiliate";



export interface IBlogPost {
    Id: string;
    Slug: string;
    Type: BlogType;
    PublishDate?: Date;
    Title: string;
    ImageUrl?: string;
    Description?: string;
    Content: IBlogArticle[];
    BuyButtonText?: string;
}

export interface IBlogArticle {
    Id: string;
    PartNumber?: number;
    Type: ArticleType;
    Header: string;
    Answer: string;
    FinalWords: string;
    Body: string;
    ImageUrl?: string;
    Affiliate1?: IAffiliate;
    Affiliate2?: IAffiliate;
    Affiliate3?: IAffiliate;
    Links?: ILink[];
    Bullets?: IArticleBullet[];
    Tool?: ToolType;
}
export interface IArticleBullet {
    Id: string;
    PartNumber: number;
    Strong: string;
    Content: string;
    Link: ILink;
    Affiliate: IAffiliate;
}

export interface ILink {
    Id: string;
    Url: string;
    Name: string;
}

export enum BlogType {
    HowTo,
    Best,
    Top
}
export enum ArticleType {
    Text,
    List
}
export enum ToolType {
    oil,
    kwh,
    intrest
}