import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA, { ga } from 'react-ga';

type AnalyticsProps = {
    initialized: boolean;
    children: React.PropsWithChildren<any>;
}

const AnalyticsWrapper: FC<AnalyticsProps> = props => {
    const location = useLocation();

    useEffect(() => {
        return () => {
            if (props.initialized) {
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
        }
    }, [props.initialized, location])
    return props.children;
}

export default AnalyticsWrapper