import React from "react";
import { FC, useEffect, useRef } from "react";


type SpinnerProps = {
}

const Spinner: FC<SpinnerProps> = props => {
    const componentIsMounted = useRef(false)

    useEffect(() => {
        componentIsMounted.current = true

        return () => {
            componentIsMounted.current = false
        }
    }, [])

    return (
        <React.Fragment>
            {
                componentIsMounted.current &&
                <div className="lds-ripple"><div>
                </div>
                    <div>
                    </div>
                </div>
            }
        </React.Fragment >
    )
}

Spinner.propTypes = {

}

Spinner.defaultProps = {

};


export default Spinner
