import { FC, useEffect, useState } from "react";
import React from "react";
import { BlogType, IArticleBullet, IBlogArticle, IBlogPost, ILink } from "../../interfaces/IBlogPost";
import parse from 'html-react-parser';
import { Helmet, HelmetProvider } from "react-helmet-async";
const ToolComponent = React.lazy(() => import('../tools/ToolComponent'));


type BlogProps = {
    Post?: IBlogPost;
}

const BlogPost: FC<BlogProps> = props => {
    const [h1, setH1] = useState<string>("Blog header");
    const [description, setDescription] = useState<string>("Blog description");

    const blogDescription = () => {
        if (props.Post?.ImageUrl !== null) {
            return (
                <div className="main__blog-image">
                    <img loading="lazy" src={props.Post?.ImageUrl} alt="" width="100%" height="auto" />
                    {/* <div className="main_blog-image-text">
                        <p>{description}</p>
                    </div> */}
                    <p>{description}</p>
                </div >
            )
        }
        else {
            return;
            // return (
            // <p>{description}</p>
            // )
        }
    };

    const extract = (delimiters: [string, string]) => {
        const [beg, end] = delimiters;
        const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
        const normalise = (str: string) => str.slice(beg.length, -end.length);
        return (str: string) => str.match(matcher)?.map(normalise);
    }

    const blogContent = props.Post?.Content?.map((content: IBlogArticle, index: number) => {
        let contentBody = content.Body;

        const extractSubstrings = extract(['{{', '}}']);
        const codeblocks = extractSubstrings(contentBody);
        codeblocks?.forEach(code => {
            contentBody = contentBody.replaceAll(`{{${code}}}`, `<code> ${code}</code>`);
        });

        if (content.Affiliate1 !== undefined) {
            contentBody = contentBody.replaceAll(content.Affiliate1?.Name, `<a classname="affiliate-link" target="_blank" href="${content.Affiliate1?.Url}"> ${content.Affiliate1?.Name}</a>`);
        }
        if (content.Affiliate2 !== undefined) {
            contentBody = contentBody.replaceAll(content.Affiliate2?.Name, `<a classname="affiliate-link" target="_blank" href="${content.Affiliate2?.Url}" > ${content.Affiliate2?.Name}</a>`);
        }
        if (content.Affiliate3 !== undefined) {
            contentBody = contentBody.replaceAll(content.Affiliate3?.Name, `<a classname="affiliate-link" target="_blank" href="${content.Affiliate3?.Url}" > ${content.Affiliate3?.Name}</a>`);
        }

        if (content.Links !== undefined) {
            content.Links.forEach((link: ILink) => {
                let href = `<a target="_blank" href="${link.Url}">${link.Name}</a>`;
                contentBody = contentBody.replaceAll(link.Name, href);
            });
        }

        let aside: JSX.Element = <aside className="main__blog-aside">
            {content.Affiliate1?.ImageUrl &&
                <div className="affiliate__container">
                    <h3>{content.Affiliate1.Name}</h3>
                    <a target="_blank" href={content.Affiliate1?.Url}>
                        <img loading="lazy" src={content.Affiliate1?.ImageUrl} alt={`image of ${content.Affiliate1?.Name || "product"}`} />
                    </a>
                    <input className="form__button-small" type="button" value={props.Post?.BuyButtonText || "Köp"} onClick={() => window.open(`${content.Affiliate1?.Url}`, '_blank')} />
                </div>
            }
            {content.Affiliate2?.ImageUrl &&
                <div className="affiliate__container">
                    <h3>{content.Affiliate2.Name}</h3>
                    <a target="_blank" href={content.Affiliate2?.Url}>
                        <img loading="lazy" src={content.Affiliate2?.ImageUrl} alt={`image of ${content.Affiliate2?.Name || "product"}`} />
                    </a>
                    <input className="form__button-small" type="button" value={props.Post?.BuyButtonText || "Köp"} onClick={() => window.open(`${content.Affiliate2?.Url}`, '_blank')} />
                </div>
            }
            {content.Affiliate3?.ImageUrl &&
                <div className="affiliate__container">
                    <h3>{content.Affiliate3.Name}</h3>
                    <a target="_blank" href={content.Affiliate3?.Url}>
                        <img loading="lazy" src={content.Affiliate3?.ImageUrl} alt={`image of ${content.Affiliate3?.Name || "product"}`} />
                    </a>
                    <input className="form__button-small" type="button" value={props.Post?.BuyButtonText || "Köp"} onClick={() => window.open(`${content.Affiliate3?.Url}`, '_blank')} />
                </div>
            }
        </aside>

        if (content.Affiliate1 == null && content.Affiliate2 == null && content.Affiliate3 == null) {
            aside = <></>
        }

        let bullets: string = "";
        let bulletAffiliates: string = "";
        if (content.Bullets?.length !== undefined && content.Bullets.length > 0) {
            content.Bullets?.forEach((bullet: IArticleBullet) => {
                let bulletLi = `<li><p><strong>${bullet.PartNumber}. ${bullet.Strong}</strong> ${bullet.Content || ""}</p></li>`

                if (bullet.Link !== undefined) {

                    let href = `<a target="_blank" href="${bullet.Link.Url}"> ${bullet.Link.Name}</a>`;
                    bulletLi = bulletLi.replaceAll(bullet.Link.Name, href);
                }

                if (bullet.Affiliate !== undefined) {

                    bulletLi = bulletLi.replaceAll(bullet.Affiliate.Name, `<a classname="affiliate-link" target="_blank" href="${bullet.Affiliate.Url}"> ${bullet.Affiliate.Name}</a>`);
                }

                if (bullet.Affiliate !== undefined) {
                    let bulletAffiliate =
                        `
                        <div>
                            <h3>${bullet.Affiliate.Name}</h3>
                            <a target="_blank" href=${bullet.Affiliate?.Url}>
                                <img loading="lazy" src=${bullet.Affiliate?.ImageUrl} alt="produktbild" />
                            </a>
                        </div>
                    `

                    bulletAffiliates = bulletAffiliates + bulletAffiliate;
                }

                bullets = bullets + bulletLi;

            });
        }

        return (
            <article key={index}>
                {(content.Header !== undefined)
                    &&
                    <h2>{content.Header}</h2>
                }
                {(content.Answer !== undefined)
                    &&
                    <h3>{content.Answer}</h3>
                }
                <p>
                    {parse(contentBody)}
                </p>
                {(content.Tool !== undefined)
                    &&
                    <ToolComponent ToolType={content.Tool} />
                }
                {
                    (content.Bullets?.length !== undefined && content.Bullets.length > 0)
                    &&
                    <ol>{parse(bullets)}</ol>
                }
                {
                    (bulletAffiliates.length > 0) &&
                    <aside className="main__blog-aside">
                        {parse(bulletAffiliates)}
                    </aside>
                }
                {(content.FinalWords !== undefined)
                    &&
                    <p>
                        {parse(content.FinalWords)}
                    </p>
                }
                {aside}
            </article >
        )
    })

    useEffect(() => {
        setH1(props.Post?.Title || "Blog header");
        setDescription(props.Post?.Description || "Blog description");

        return () => {
        }
    }, [props.Post])


    useEffect(() => {
    }, [])

    return (
        <HelmetProvider>
            <main className="main__blog">
                <header className="main__blog-header">
                    <h1>{h1}</h1>
                    {blogDescription()}
                </header>
                <section aria-labelledby={h1} className="main__blog-articles">
                    {blogContent}
                </section>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{h1}</title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description" content={description} />
                    <meta property="og:image" content={props.Post?.ImageUrl} />
                    <meta property="og:type" content="article" />
                    <meta content="sv_SE" property="og:locale" />
                </Helmet>
            </main>
        </HelmetProvider>
    )
}

BlogPost.propTypes = {
}

BlogPost.defaultProps = {
    Post: {
        Id: "1",
        Slug: "nar-ska-man-forsa",
        Type: BlogType.HowTo,
        Title: "När ska man förså, vad ska man tänka på",
        ImageUrl: "",
        Description: "Här ger vi sex korta tips på hur du skall tänka för en lyckad försådd.",
        Content: [

        ]
    }
};

export default BlogPost