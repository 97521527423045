import React, { useState } from 'react'
import ReactGA, { ga } from 'react-ga';

export function useAnalytics() {
    const [initialized, setInitialized] = useState<boolean>(false);

    React.useEffect(() => {
        if (window.location.href.includes("localhost") === true) {
            ReactGA.initialize("G-N7CJR743SX", { useExistingGa: true });
            // ReactGA.initialize("G-N7CJR743SX", { useExistingGa: true });
            // ga("create", "G-N7CJR743SX", 'auto', { cookieFlags: "SameSite=None; Secure" });
            setInitialized(true);
        }
    }, [])
    return (initialized);
}

export default useAnalytics