import { FC, useEffect, useState } from "react";

type LogoProps = {
    type: string,
    height: number,
    width: number
}

export const Logo: FC<LogoProps> = props => {
    const logoColor: string = require('../../assets/logo/open-garden-color.webp');
    const logoWhite: string = require('../../assets/logo/open-garden-white.webp');
    const logoBlack: string = require('../../assets/logo/open-garden-black.webp');

    const [logo, setLogo] = useState<string>(logoColor);

    useEffect(() => {
        setLogoType(props.type);
    }, [])

    function setLogoType(type: string) {
        switch (type) {
            case "color":
                setLogo(logoColor);
                break;
            case "black":
                setLogo(logoBlack);
                break;
            case "white":
                setLogo(logoWhite);
                break;
            default:
                setLogo("");
                break;
        }
    }

    return (
        <div className="logo">
            <img className="logo__img" src={logo} alt="Open garden logo" height={props.height} width={props.width} />
        </div >
    )
}

Logo.propTypes = {

}

Logo.defaultProps = {

};

export default Logo