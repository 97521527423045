import { IGeolocation } from "../interfaces/IGeolocation";

class GeoHelper {
    private static Geolocation: IGeolocation | undefined = undefined;
    public static geo_url: string = "https://geolocation-db.com/json/";

    //https://kentcdodds.com/blog/using-fetch-with-type-script

    public static async GetGeolocation(): Promise<IGeolocation> {
        const response = await fetch(this.geo_url, {
            method: 'GET',
            // headers: {
            //     // 'Access-Control-Allow-Origin': 'http://localhost:3000',
            //     // 'Access-Control-Allow-Credentials': 'true',
            //     'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
            //     'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
            // }
        });
        const result: { data: IGeolocation, errors: any } = await response.json();
        return result.data;
    }
}

export { GeoHelper };