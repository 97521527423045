import React from "react";
import { FC, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";



type HeaderNavProps = {

}

export const HeaderNav: FC<HeaderNavProps> = props => {
    const componentIsMounted = useRef(true)

    let activeStyle = {
        textDecoration: "underline",
    };

    let activeClassName = "underline";

    useEffect(() => {
        componentIsMounted.current = true

        return () => {
            componentIsMounted.current = false
        }
    }, [])

    return (
        <React.Fragment>
            {
                <nav className="nav">
                    <ul>
                        <li>
                            <NavLink to="/home" className={({ isActive }) => isActive ? activeClassName : undefined}>Hem</NavLink>
                        </li>
                        <li>
                            <NavLink to="/blog/archive" className={({ isActive }) => isActive ? activeClassName : undefined}>Blogg</NavLink>
                        </li>
                        <li>
                            <NavLink to="/tools" className={({ isActive }) => isActive ? activeClassName : undefined}>Verktyg</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="about" className={({ isActive }) => isActive ? activeClassName : undefined}>
                                Om
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
            }
        </React.Fragment>
    )
}

HeaderNav.propTypes = {

}

HeaderNav.defaultProps = {

};


export default HeaderNav