import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';



// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// const root = document.getElementById("root");


const container = document.getElementById('root');
const root = createRoot(container!);

const app = (

  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

if (container!.hasChildNodes()) {
  hydrateRoot(container!, app);
} else {
  root.render(app);
}

// root.render(

//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>

//   //Routes
//   //https://blog.webdevsimplified.com/2022-07/react-router/


//   // <React.StrictMode>
//   //   <App />
//   // </React.StrictMode>
// );

serviceWorkerRegistration.unregister();
reportWebVitals();
